<template>
  <div>
    <gl-title :title="$route.meta.title"></gl-title>
    <div style="margin: 18px;" v-check="'103021'">
      <el-button v-check="'103021'" type="primary" size="mini" @click="onClickAction('updateVisible')"> 新增 </el-button>
    </div>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="productNumber" label="产品编号">
          <gl-list-search v-model.trim="mFormData.productNumber" placeholder="产品编号" @enter="mGetList"></gl-list-search>
        </el-form-item>
        <el-form-item prop="productName" label="产品名称">
          <gl-list-search v-model.trim="mFormData.productName" placeholder="产品名称" @enter="mGetList"></gl-list-search>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="productNumber" label="产品编号"></el-table-column>
        <el-table-column prop="productName" label="产品名称"></el-table-column>
        <el-table-column prop="designerName" label="设计师名字"></el-table-column>
        <el-table-column prop="divide" label="设计师分成比例"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button v-check="'103022'" type="text" @click="onClickAction('updateVisible', row)"> 编辑 </el-button>
            <el-button type="text" @click="onClickAction('previewVisible', row)"> 查看 </el-button>
            <el-button v-check="'103023'" type="text" @click="mDelete(row.productId)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <add-product :visible.sync="updateVisible" :itemId="itemId" @reload="mReload"></add-product>
    <preview  :visible.sync="previewVisible" :itemId="itemId"/>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import {productList, productDelete} from '@/api/backendall/goods'
import addProduct from './addProduct.vue'
import preview from './preview.vue'
export default {
  components: {addProduct, preview},
  mixins: [ListMixin],
  data(){
    return{
      updateVisible: false,
      previewVisible: false,
      itemId: ''
    }
  },
  mounted(){
    this.mGetListFun = productList
    this.mDeleteFun = productDelete
    this.mGetList()
  },
  methods: {
    onClickAction(name, item){
      this.itemId = item?.productId || '' 
      this[name] = true
    }
  },
}
</script>

<style>

</style>