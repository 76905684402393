<template>
  <el-dialog 
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
  >
    <el-form :model="formData" ref="form" label-width="130px" size="mini" v-loading="loading">
      <el-form-item label="产品编号">
        <div> {{formData.productNumber}} </div>
      </el-form-item>
      <el-form-item label="产品名称">
        <div> {{formData.productName}} </div>
      </el-form-item>
      <el-form-item label="产品尺度">
        <div> {{formData.productSize}} </div>
      </el-form-item>
      <el-form-item label="设计师名字">
        <div> {{formData.designerName}} </div>
      </el-form-item>
      <el-form-item label="设计师分成比例">
        <div> {{formData.divide}} </div>
      </el-form-item>
    </el-form> 
    <gl-flex>
      <el-button @click="handleClose" >取消</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {productById} from '@/api/backendall/goods'

export default {
  name: 'preview',
  props: {
    itemId: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  watch: {
    itemId: {
      handler: function(newVal,oldval){
        newVal && this.getProduct(newVal)
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    handleClose(){
      this.$emit('update:visible', false)
    },
    async getProduct(id){
      this.loading = true
      const res = await productById(id)
      this.formData = res.data
      this.loading = false
    }
  },
}
</script>

<style>

</style>