<template>
  <el-dialog 
    title="新增"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="650px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="140px" width="500px" size="mini" v-loading="loading" >
        <el-form-item prop="productNumber" label="产品编号" verify>
          <el-input v-model="formData.productNumber"></el-input>
        </el-form-item>
        <el-form-item prop="productName" label="产品名称" verify>
          <el-input v-model="formData.productName"></el-input>
        </el-form-item>
        <el-form-item prop="productName" label="产品尺度" verify>
          <el-input v-model="formData.productSize"></el-input>
        </el-form-item>
        <el-form-item prop="designerName" label="设计师名字" verify>
          <el-input v-model="formData.designerName"></el-input>
        </el-form-item>
        <el-form-item prop="productMaterial" label="材质" verify>
          <el-input v-model="formData.productMaterial"></el-input>
        </el-form-item>
        <el-form-item prop="divide" label="设计师分成比例%" verify>
          <el-input v-model.number="formData.divide" :maxlength="3"></el-input>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {productAdd, productUpdate, productById} from '@/api/backendall/goods'
export default {
  name: 'addProduct',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      formData: {},
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getProduct(this.itemId)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        console.log('formData:', this.formData)
        this.loading = true
        let res = null
        if(this.itemId){
          res = await productUpdate(this.formData)
        }else {
          res = await productAdd(this.formData)
        }
        this.loading = false
        this.handleClose()
        this.$message.success('操作成功')
        this.$emit('reload')
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
    async getProduct(id){
      this.loading = true
      const res = await productById(id)
      this.formData = {
        productId: res.data.productId,
        productNumber: res.data.productNumber,
        productName: res.data.productName,
        productSize: res.data.productSize,
        designerName: res.data.designerName,
        divide: res.data.divide,
        productMaterial: res.data.productMaterial,
      }
      this.loading = false
    }
  }
}
</script>

<style>

</style>